<template>
  <transition
    enter-active-class="chatEnter animated slideInRight faster"
    leave-active-class="chatLeave"
    appear
    @enter="onChatOpen"
    @leave="onChatClose"
  >
    <div class="chat-component" :class="{ 'has-outline': showChatTransparent }">
      <!-- TITLEBAR -->
      <ChatTitlebar :title="chatTitle" />

      <!-- BODY -->
      <div class="chat-body">
        <!-- CHAT ALERTS -->
        <ChatAlerts />

        <keep-alive>
          <transition-group
            enter-active-class="animated slideInRight faster"
            leave-active-class="animated slideOutLeft faster"
            mode="out-in"
          >
            <!-- YOUTUBE PLAYLIST -->
            <YouTubePlaylist
              v-show="showYouTubePlaylist"
              key="chat-playlist"
              :showing="showYouTubePlaylist"
            />

            <!-- CHAT MESSAGES -->
            <!-- class="cover " -->
            <ChatMessages v-show="!showYouTubePlaylist" key="chat-messages" :top-to-bottom="true" />
          </transition-group>
        </keep-alive>
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapGetters } from "vuex";
  import ChatAlerts from "./ChatAlerts.vue";
  import ChatMessages from "./ChatMessages.vue";
  import ChatTitlebar from "./ChatTitlebar.vue";
  import YouTubePlaylist from "../YouTubePlayer/YouTubePlaylist/YouTubePlaylist.vue";

  export default {
    components: {
      ChatAlerts,
      ChatMessages,
      ChatTitlebar,
      YouTubePlaylist,
    },
    data() {
      return {
        dev: false, //window.debug,
        lastViewerCount: 0,
      };
    },
    computed: {
      ...mapGetters({
        followerCount: "twitch/getFollowerCount",
        followerGoal: "twitch/getFollowerGoal",
        latestFollower: "twitch/getLatestFollower",
        viewerCount: "twitch/getViewerCount",
        getActiveTask: "tasks/getActiveTask",
      }),
      chatTitle() {
        return this.showYouTubePlaylist ? "Playlist" : "Chat";
      },
      showChatTransparent() {
        return !!this.getActiveTask("chat", "Transparent");
      },
      showYouTubePlaylist() {
        return !!this.getActiveTask("chat", "YouTube Playlist");
      },
    },
    watch: {
      //--------------------------------------------------------
      // WATCH: LATEST FOLLOWER
      //--------------------------------------------------------

      latestFollower() {
        // PLAY "DOUBLE CHIME" SOUND
        this.$playSound("new-follow", { volume: 0.2, interrupt: true });
      },

      //--------------------------------------------------------
      // VIEWER COUNT
      //--------------------------------------------------------

      viewerCount() {
        // Knock for the first 20 viewers, reset on zero (when they leave)
        if (
          this.viewerCount > 0 &&
          this.viewerCount <= 20 &&
          this.viewerCount > this.lastViewerCount
        ) {
          // PLAY SOUND
          this.$playSound("first-3-viewers", { volume: 0.2, interrupt: true });
        } else if (
          this.viewerCount > 0 &&
          this.viewerCount <= 25 &&
          this.viewerCount > this.lastViewerCount
        ) {
          // PLAY SOUND
          this.$playSound("new-viewer", { volume: 0.2, interrupt: true });
        }

        this.lastViewerCount = this.viewerCount;
      },
    },
    created() {
      // connect?
    },
    methods: {
      onChatOpen() {
        this.$playSound("icq-message", { interrupt: true, volume: 0.6 }); // PLAY SOUND
      },
      onChatClose() {
        this.$playSound("icq-msg-sent", { interrupt: true, volume: 0.6 }); // PLAY SOUND
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chatLeave {
    animation: stage-chat-leave 500ms ease 0s alternate 1 forwards;
    @keyframes stage-chat-leave {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(calc(-100% - #{rem-calc(70)}));
      } // the top offset
    }
  }

  // .chatLeave {
  // 	animation: chat-leave 400ms ease 0s alternate 1 forwards;
  // 	@keyframes chat-leave {
  // 		from { transform: translateX(0); }
  // 		to   { transform: translateX(110%); } // the top offset / - #{rem-calc(70)}
  // 	}
  // }

  //-----------------------------------------------------------------
  // CHAT COMPONENT
  //-----------------------------------------------------------------

  .chat-component {
    position: absolute;
    top: rem-calc(65);
    right: rem-calc(20);
    width: rem-calc(400);
    height: rem-calc(608);
    // border: 1px dashed yellow;
    border-radius: 10px;
    box-shadow: 0 5px 20px 15px rgba(black, 0.2);
    overflow: hidden;
    z-index: 2;
  }

  //-----------------------------------------------------------------
  // CHAT BODY
  //-----------------------------------------------------------------

  .chat-body {
    position: relative;
    top: rem-calc(30);
    width: 100%;
    height: calc(100% - #{rem-calc(30)});
    // background-color: rgba(black, 0.8);
    // background-color: rgba(black, 0.4);
    // border: 1px dashed yellow;
    // overflow: hidden;
    border-radius: 0 0 10px 10px;

    // background: linear-gradient(to top, rgba(black, 0.8) 20%, rgba(black, 0.4) 100%); // GOOD
    background: linear-gradient(to top, rgba(black, 0.9) 20%, rgba(black, 0.6) 100%);
    // backdrop-filter: blur(6px);
  }

  //-----------------------------------------------------------------
  // MODIFIERS
  //-----------------------------------------------------------------

  .chat-component.has-outline {
    box-shadow: 0 0 0 1px rgba(white, 0.2);

    .chat-body,
    .window-titlebar {
      background: rgba(black, 0.2) !important;
    }
  }
</style>
